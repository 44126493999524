<template>
    <div>
        <page-title  :heading="$t('reports.lang_lists') " :subheading="$t('reports.lang_lists')" :icon=icon></page-title>
        <div class="app-main__inner">
        <Lists></Lists>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import Lists from "../../components/reports/Lists";

    export default {
        components: {
            PageTitle,
          Lists
        },
        data: () => ({
            icon: 'pe-7s-news-paper icon-gradient bg-tempting-azure',
        })
    }
</script>